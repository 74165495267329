/* You can add global styles to this file, and also import other style files */
@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import 'assets/theme/theme.scss';
@import 'assets/theme/material-overrides.scss';

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

::-webkit-scrollbar {
  width: 9px;
  background: #e7e7e7;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  background: #999999;
  border-radius: 100px;
}
