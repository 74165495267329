.backdrop-message-dialog-darker {
  background-color: #000000bf;
  backdrop-filter: blur(10px);
}

.message-dialog-darker,
.message-dialog-darker .mat-mdc-dialog-container {
  --mdc-dialog-container-shape: 20px;
}

.mat-mdc-snack-bar-container {
  &.test-renderer-error-snackbar {
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #cd3a3c;
    --mdc-snackbar-supporting-text-weight: bold;
    letter-spacing: 0.8px;
  }
}
